import { faCheck, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as RadixSelect from '@radix-ui/react-select';

import { ColorsTokens } from '@/styles/theme/colorTokens';

import { RMText } from '../RMText/RMText.js';

import {
  SelectContent,
  SelectionIcon,
  SelectItem,
  SelectTextItem,
  SelectTrigger,
  Separator,
} from './RMContextMenuSelect.styles.js';

export interface RMContextMenuSelectOption {
  label: string;
  value: string;
  color?: keyof typeof ColorsTokens;
}

export interface RMContextMenuSelectAction {
  id: string;
  label: string;
  onSelect: () => void;
  color?: keyof typeof ColorsTokens;
}

interface RMContextMenuSelectProps {
  label?: string;
  value?: RMContextMenuSelectOption['value'] | null;
  options: RMContextMenuSelectOption[];
  actions?: RMContextMenuSelectAction[];
  onChange?: (value: RMContextMenuSelectOption['value']) => void;
}

export function RMContextMenuSelect({ label, value, options, actions, onChange }: RMContextMenuSelectProps) {
  const onSelect = (value: string) => {
    if (value.startsWith('action-')) {
      const action = actions?.find((action) => value.replace('action-', '') === action.id);
      action?.onSelect?.();
    } else {
      onChange?.(value);
    }
  };

  return (
    <RadixSelect.Root value={value || undefined} onValueChange={onSelect}>
      <SelectTrigger>
        <RadixSelect.Value placeholder={label} />
        <SelectionIcon>
          <FontAwesomeIcon size="xs" icon={faChevronDown} color={ColorsTokens['on-surface-primary']} />
        </SelectionIcon>
      </SelectTrigger>
      <SelectContent>
        <RadixSelect.Viewport>
          <RadixSelect.Group>
            {options.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                <RadixSelect.ItemText>
                  <RMText type="sans" size="xs" bold="medium" color={option?.color || 'primary'}>
                    <SelectTextItem>
                      {value === option.value && <FontAwesomeIcon icon={faCheck} color={ColorsTokens.primary} />}
                      {option.label}
                    </SelectTextItem>
                  </RMText>
                </RadixSelect.ItemText>
              </SelectItem>
            ))}
          </RadixSelect.Group>
          {actions && (
            <>
              <Separator />

              <RadixSelect.Group>
                {actions?.map((action) => (
                  <SelectItem key={action.id} value={`action-${action.id}`}>
                    <RadixSelect.ItemText>
                      <RMText type="sans" size="xs" bold="medium" color={action?.color || 'primary'}>
                        {action.label}
                      </RMText>
                    </RadixSelect.ItemText>
                  </SelectItem>
                ))}
              </RadixSelect.Group>
            </>
          )}
        </RadixSelect.Viewport>
      </SelectContent>
    </RadixSelect.Root>
  );
}
