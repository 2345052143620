import { styled } from '@linaria/react';
import * as RadixSelect from '@radix-ui/react-select';

export const SelectTrigger = styled(RadixSelect.SelectTrigger)`
  all: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  background-color: var(--surface);
  outline: none;
  cursor: pointer;

  span {
    color: var(--on-surface-primary);
  }

  svg[data-icon='check'] {
    display: none;
  }
`;

export const SelectContent = styled(RadixSelect.Content)`
  overflow: hidden;
  background-color: var(--surface);
  border-radius: var(--radius-regular);
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  width: 256px;
  padding: 8px 0;
  z-index: 10;
`;

export const Separator = styled(RadixSelect.Separator)`
  border: 1px dashed #d5d0c3;
  margin: 0 var(--spacing-md);
`;

export const SelectItem = styled(RadixSelect.Item)`
  cursor: pointer;
  padding: var(--spacing-sm) var(--spacing-md) var(--spacing-sm) 48px;
  position: relative;

  &:hover {
    background: var(--darken-neutral-hover);
    outline: none;
  }

  &:active {
    background: var(--darken-neutral-press);
  }

  &:focus-visible {
    outline: none;
  }

  svg {
    position: absolute;
    width: 16px;
    padding: 0 16px;
    left: 0;
  }
`;

export const SelectionIcon = styled(RadixSelect.Icon)`
  margin-left: 8px;
`;

export const SelectTextItem = styled.div`
  display: flex;
  align-items: center;
  line-height: 1rem;
`;
